const state = () => ({
    routeLoad: false,
})

const getters = {
    getRouteLoad: (state) => {
        return state.routeLoad
    },

}

const actions = {
    setRouteLoad({commit}) {
        commit('SET_ROUTE_LOAD')
    },

}

const mutations = {
    SET_ROUTE_LOAD(state) {
        state.routeLoad = true
    },

}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
