const state = () => ({
    content: {},
    animated: false,
    opened: false,
    historySliderOpened: false,
    transformY: 0
})

const getters = {
    getContent: (state) => {
        return state.content
    },
    getAnimated: (state) => {
        return state.animated
    },
    getOpened: (state) => {
        return state.opened
    },
    getHistoryOpened: (state) => {
        return state.historySliderOpened
    },
    getTransformY: (state) => {
        return state.transformY
    },
}

const actions = {
    setContent({commit}, value) {
        commit('SET_CONTENT', value)
    },
    setAnimated({commit}, value) {
        commit('SET_ANIMATED', value)
    },
    setOpened({commit}, value) {
        commit('SET_OPENED', value)
    },
    setHistoryOpened({commit}, value) {
        commit('SET_HISTORY_OPENED', value)
    },
    setTransformY({commit}, value) {
        commit('SET_TRANSFORM_Y', value)
    },
}

const mutations = {
    SET_CONTENT(state, value) {
        state.content = value
    },
    SET_ANIMATED(state, value) {
        state.animated = value
    },
    SET_OPENED(state, value) {
        state.opened = value
    },
    SET_HISTORY_OPENED(state, value) {
        state.historySliderOpened = value
    },
    SET_TRANSFORM_Y(state, value) {
        state.transformY = value
    },

}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
