const state = () => ({
    scroll: null,
})

const getters = {
    getScroll: (state) => {
        return state.scroll
    },

}

const actions = {
    setScroll({commit}, value) {
        commit('SET_SCROLL', value)
    },

}

const mutations = {
    SET_SCROLL(state, value) {
        state.scroll = value
    },

}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
