import {mapGetters} from "vuex";
import get from 'lodash/get'
import first from 'lodash/first'
import last from 'lodash/last'
import axios from "@/scripts/api";

export default {
    computed: {
        ...mapGetters({
            lang: 'language/getLang',
            translations: 'translations/translations'
        })
    },
    methods: {
        _tr(word) {
            if (!word) return
            const trim = (w) => {
                return w?.toLowerCase()?.replace(/ /g, '')
            }
            let translationData = get(this.translations, this.lang) || {};
            let trWord = Object.entries(translationData)?.find(item => trim(first(item)) === trim(word))
            if (!trWord) {
                axios('/adwrd', {wrd: word}).catch(function (error) {
                    console.log(error);
                })
            }
            return trWord ? last(trWord) : word
        },
    },
}
