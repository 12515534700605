import axios from "@/scripts/api.js";
import unionBy from 'lodash/unionBy'

const state = () => ({
    visible: false,
    menu: [],
    pagesData: [],
    activePageId: '',
    pageIsLoaded: false,
    indexData: {},
    routeUpdateFromMenu: false,
    pending: false
})

const getters = {
    getVisible: (state) => {
        return state.visible
    },
    getMenu: (state) => {
        return state.menu
    },
    getIndexData: (state) => {
        return state.indexData
    },
    getPage: (state) => {
        return state.pagesData?.find(page => {
            return page.id === state.activePageId?.toString()
        })
    },
    getPageIsLoaded: (state) => {
        return state.pageIsLoaded
    },
    getPagesData: (state) => {
        return state.pagesData
    },
    getActivePageId: (state) => {
        return state.activePageId
    },
    getCurrentPageMenuData: (state) => {
        let ifSingle = false
        if (typeof state.activePageId === 'string') ifSingle = parseInt(state.activePageId?.split('-')?.[0])
        return state.menu.find(item => item.id === state.activePageId || item.id === ifSingle)
    },
    getRouteUpdateFromMenu(state) {
        return state.routeUpdateFromMenu
    },
    getPending: (state) => {
        return state.pending
    }
}

const actions = {
    setVisible({commit}, value) {
        commit('SET_VISIBLE', value)
    },
    getMenuData({commit}) {
        return new Promise((resolve) => {
            axios('/indx')
                .then((response) => {
                    commit('SET_MENU_DATA', response.data.menus)
                    commit('SET_INDEX_DATA', response.data)
                    resolve(response)
                })
                .catch(function (error) {
                    console.log(error);
                })
        })
    },
    getPageData({commit, rootGetters}, {
        singleview,
        contentid
    }) {
        let id = singleview ? `${contentid}singleview-${singleview}` : contentid

        commit('SET_ACTIVE_PAGE_ID', id)
        commit('SET_PENDING', true)
        axios('/getCurrentContent', {
            contentid: Number(contentid),
            singleview: Number(singleview),
            selectedlan: rootGetters["language/getLang"]
        }).then((response) => {
            commit('SET_PAGES_DATA', {
                id: id.toString(),
                data: response.data?.secondary,
                primary: response.data?.primary,
            });
            commit('SET_PENDING', false)
        }).catch(function (error) {
            console.log(error);
        })
    },
    setPageIsLoaded({commit}, value) {
        commit('SET_PAGE_IS_LOADED', value)
    },
    setRouteUpdateFromMenu({commit}, value) {
        commit('SET_ROUTE_UPDATE_FROM_MENU', value)
    }
}

const mutations = {
    SET_VISIBLE(state, value) {
        state.visible = value
    },
    SET_MENU_DATA(state, value) {
        state.menu = value
    },
    SET_INDEX_DATA(state, value) {
        state.indexData = value
    },
    SET_ACTIVE_PAGE_ID(state, value) {
        state.activePageId = value
    },
    SET_PAGES_DATA(state, page) {
        state.pagesData = unionBy([page], state.pagesData, 'id')
        // state.pagesData.push(page)
    },
    SET_PAGE_IS_LOADED(state, value) {
        state.pageIsLoaded = value
    },
    SET_ROUTE_UPDATE_FROM_MENU(state, value) {
        state.routeUpdateFromMenu = value
    },
    SET_PENDING(state, value) {
        state.pending = value
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
