import axios from "@/scripts/api";

const state = () => ({
    translations: {},
})

const getters = {
    translations: (state) => {
        return state.translations
    },
}

const actions = {
    getTranslations({commit}) {
        axios('/getTranslations').then((value) => {
            commit('SET_TRANSLATIONS', value?.data)

        }).catch(function (error) {
            console.log(error);
        })
    },
}

const mutations = {
    SET_TRANSLATIONS(state, value) {
        state.translations = value
    },

}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
