export const DEFAULT_LANGUAGE = "en";
export const FALLBACK_LANGUAGE = "en";
export const SUPPORTED_LANGUAGES = [
    {
        id: "en",
        title: "Eng",
        longTitle: "English"
    },
    {
        id: "ru",
        title: "Rus",
        longTitle: "Russian"
    },
    {
        id: "ge",
        title: "Geo",
        longTitle: "Georgian"
    }
];
