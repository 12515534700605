const state = () => ({
    ageVerified: false,
})

const getters = {
    getAgeVerified: (state) => {
        return state.ageVerified
    },

}

const actions = {
    setAgeVerified({commit}) {
        commit('SET_AGE_VERIFIED')
    },

}

const mutations = {
    SET_AGE_VERIFIED(state) {
        state.ageVerified = true
    },

}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
