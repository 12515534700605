import headerType from "@/constants/headerTypes";

const state = () => ({
    showPageName: false,
    headerType: headerType.PAGE_HEADER,
    transparent_percentage: null,
    transparent_percentage2: null,
    darkTemplate: true,
    darkTemplate2: true,
    textWipe: true
});

const getters = {
    getShowPageName: state => {
        return state.showPageName;
    },
    getHeaderType(state) {
        return state.headerType;
    },
    getTransparentPercentage(state) {
        return state.transparent_percentage;
    },
    getTransparentPercentage2(state) {
        return state.transparent_percentage2;
    },
    getDarkTemplate(state) {
        return state.darkTemplate;
    },
    getDarkTemplate2(state) {
        return state.darkTemplate2;
    }
};

const actions = {
    setShowPageName({ commit }, value) {
        commit("SET_SHOW_PAGE_NAME", value);
    },
    deleteText({ commit }, value) {
        commit("DELETE_TEXT", value);
    },
    setHeaderType({ commit }, value) {
        commit("SET_HEADER_TYPE", value);
    },
    setTransparentPercentage({ commit }, value) {
        commit("SET_TRANSPARENT_PERCENTAGE", value);
    },
    setTransparentPercentage2({ commit }, value) {
        commit("SET_TRANSPARENT_PERCENTAGE2", value);
    },
    setDarkTemplate({ commit }, value) {
        commit("SET_DARK_TEMPLATE", value);
    },
    setDarkTemplate2({ commit }, value) {
        commit("SET_DARK_TEMPLATE2", value);
    }
};

const mutations = {
    DELETE_TEXT(state, value) {
        state.textWipe = value;
    },
    SET_SHOW_PAGE_NAME(state, value) {
        state.showPageName = value;
    },
    SET_HEADER_TYPE(state, value) {
        state.headerType = value;
    },
    SET_TRANSPARENT_PERCENTAGE(state, value) {
        state.transparent_percentage = value;
    },
    SET_TRANSPARENT_PERCENTAGE2(state, value) {
        state.transparent_percentage2 = value;
    },
    SET_DARK_TEMPLATE(state, value) {
        state.darkTemplate = value;
    },
    SET_DARK_TEMPLATE2(state, value) {
        state.darkTemplate2 = value;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
