export default {
    data() {
        return {
            mouseTypes: {
                Default: "Default",
                Stuck: "Stuck",
                Landing: "Landing",
                Header: "Header",
                ImageScale: "ImageScale",
                ImageDown: "ImageDown",
                VideoScale: "VideoScale",
                VideoDown: "VideoDown",
                Explore: "Explore",
                WineCollection: "WineCollection",
                Slider: "Slider",
                BlendMode: "BlendMode",
                Custom: "Custom",
            }
        }
    }
}
