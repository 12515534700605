import {DEFAULT_LANGUAGE} from '@/constants/trans'

const state = () => ({
    lang: DEFAULT_LANGUAGE,
})

const getters = {
    getLang: (state) => {
        return state.lang
    },
}

const actions = {
    setLang({commit}, value) {
        commit('SET_LANG', value)
    },
}

const mutations = {
    SET_LANG(state, value) {
        state.lang = value
    },

}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
