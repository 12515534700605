import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import MouseTypes from "./mixins/MouseTypes";
import translation from "./mixins/translation";
import observerDirective from "@/directives/observerDirective";
import imageHoverDirective from "@/directives/imageHoverDirective";
import Vue2TouchEvents from "vue2-touch-events";
import VueMeta from "vue-meta";
import SlideUpDown from "vue-slide-up-down";

import "@/assets/scss/font.scss";
import simpleParallax from "simple-parallax-js";

Vue.config.productionTip = false;

import gsap from "gsap";
import { PixiPlugin } from "gsap/PixiPlugin.js";
import { MotionPathPlugin } from "gsap/MotionPathPlugin.js";
import { CustomEase } from "gsap/dist/CustomEase";
import { Draggable } from "gsap/dist/Draggable";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";

gsap.registerPlugin(
    CustomEase,
    Draggable,
    ScrollTrigger,
    PixiPlugin,
    MotionPathPlugin
);

Vue.mixin({
    created: function() {
        this.gsap = gsap;
        this.CustomEase = CustomEase;
        this.Draggable = Draggable;
        this.ScrollTrigger = ScrollTrigger;
        this.simpleParallax = simpleParallax;
    }
});

import { Swiper, EffectFade } from "swiper";
import VueAwesomeSwiper from "vue-awesome-swiper";
import "swiper/swiper-bundle.css";
import PortalVue from "portal-vue";
import * as VueGoogleMaps from "vue2-google-maps";

Vue.use(VueGoogleMaps, {
    load: {
        key: "AIzaSyA2lwUG3rCX6H44Pyyo43GDZuAfPYMeQqw",
        libraries: "places"
    },

    installComponents: true
});
Vue.use(Vue2TouchEvents);
Vue.use(PortalVue);
Vue.use(VueMeta);
Vue.use(SlideUpDown);
Vue.component("slide-up-down", SlideUpDown);

import lineClamp from "vue-line-clamp";

Vue.use(lineClamp, {});
import VueSocialSharing from "vue-social-sharing";

Vue.use(VueSocialSharing);
Swiper.use([EffectFade]);
Vue.use(VueAwesomeSwiper);
Vue.directive("observer", observerDirective);
Vue.directive("hovered", imageHoverDirective);

Vue.mixin(MouseTypes);
Vue.mixin(translation);

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount("#app");
