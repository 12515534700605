const state = () => ({
    dragging: false,
})

const getters = {
    getDragging(state) {
        return state.dragging
    }
}

const actions = {
    setDragging({commit}, value) {
        commit('SET_DRAGGING', value)
    },
}

const mutations = {
    SET_DRAGGING(state, value) {
        state.dragging = value
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
