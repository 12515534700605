const observer = (elm, observerConfig) => {
    let visible = false

    const onEntry = (entry) => {
        entry.forEach((change) => {
            if (visible) return;
            if (change.isIntersecting) {
                change?.target.classList?.add('observed')
                visible = true;
                if (typeof observerConfig?.callBack === 'function' && observerConfig?.animateOnObserve) observerConfig?.callBack()
            }
        });
    };

    let observer = new IntersectionObserver(onEntry, {});


    observer.observe(elm);
};

const observerDirective = {
    bind(el, binding) {
        observer(el, binding.value)
    },


    unbind(el, binding) {
        el.removeEventListener(binding.arg, observer);
    },
};

export default observerDirective;
