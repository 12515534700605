const state = () => ({
    event: { x: 0, y: 0 },
    mouseType: "Default",
    mouseTarget: "",
    disableMouseMove: false,
    color: "",
    activeIndex: false
});

const getters = {
    getEvent: state => {
        return state.event;
    },
    getMouseType: state => {
        return state.mouseType;
    },
    getMouseTarget: state => {
        return state.mouseTarget;
    },
    getDisableMouseMove: state => {
        return state.disableMouseMove;
    },
    getColor: state => {
        return state.color;
    }
};

const actions = {
    setPosition({ commit }, value) {
        commit("SET_POSITION", value);
    },
    setMouseType({ commit }, value) {
        commit("SET_MOUSE_TYPE", value);
    },
    setMouseTarget({ commit }, value) {
        commit("SET_MOUSE_TARGET", value);
    },
    setDisableMouseMove({ commit }, value) {
        commit("SET_DISABLE_MOUSE_MOVE", value);
    },
    setColor({ commit }, value) {
        commit("SET_COLOR", value);
    },
    setActiveIndex({ commit }, value) {
        commit("SET_ACTIVE_INDEX", value);
    }
};

const mutations = {
    SET_POSITION(state, value) {
        if (value) state.event = value;
    },
    SET_MOUSE_TYPE(state, value) {
        if (value) state.mouseType = value;
    },
    SET_MOUSE_TARGET(state, value) {
        if (value) state.mouseTarget = value;
    },
    SET_DISABLE_MOUSE_MOVE(state, value) {
        state.disableMouseMove = value;
    },
    SET_COLOR(state, value) {
        state.color = value;
    },
    SET_ACTIVE_INDEX(state, value) {
        state.activeIndex = value;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
