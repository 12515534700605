import axios from "axios";
import store from "@/store/index";

let url = "https://gws.palindroma.ge/source/api/view/main/";

// console.log(process.env, window.location);
if (process.env.NODE_ENV === "production")
    url = window.location.origin + "/source/api/view/main/";
const Axios = axios.create({
    baseURL: url
});

const request = (url, data) => {
    return Axios(url, {
        method: "POST",
        headers: {
            lang: store?.getters?.["language/getLang"] || "en"
        },
        data: data
    });
};

export default request;
