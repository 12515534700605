const state = () => ({
    content: {},
    scrollBreak: false
});

const getters = {
    getContent: state => {
        return state.content;
    },
    getScrollBreak: state => {
        return state.scrollBreak;
    }
};

const actions = {
    setContent({ commit }, value) {
        commit("SET_CONTENT", value);
    },
    setScrollBreak({ commit }, value) {
        commit("SET_SCROLL_BREAK", value);
    }
};

const mutations = {
    SET_CONTENT(state, value) {
        state.content = value;
    },
    SET_SCROLL_BREAK(state, value) {
        state.scrollBreak = value;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
