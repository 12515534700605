<template>
    <div id="app">
        <router-view></router-view>
    </div>
</template>

<script>
import _ from "lodash";
import { mapGetters } from "vuex";
import { SUPPORTED_LANGUAGES } from "@/constants/trans";
export default {
    computed: {
        ...mapGetters({
            menu: "menu/getMenu",
            selected_menu: "menu/getCurrentPageMenuData",
            lang: "language/getLang",
            indexData: "menu/getIndexData",
            pageData: "menu/getPagesData"
        }),
        activeLanguage() {
            return (
                SUPPORTED_LANGUAGES?.find(item => item.id === this.lang) || {}
            );
        },
        matchedWineData() {
            const isWines =
                (this.$route.params.lang === "wines" &&
                    this.$route.params.path1?.length) ||
                (this.$route.params.path1 === "wines" &&
                    this.$route.params.path2?.length);
            const wineData = Object.values(this.pageData?.[0]?.data || {});
            if (!isWines || !wineData?.length) return null;

            return wineData?.[0]?.data?.list?.find(wine =>
                this.$route.params.lang === "wines"
                    ? wine?.slug === this.$route.params.path1
                    : wine?.slug === this.$route.params.path2
            );
        }
    },
    metaInfo() {
        const innerSeo = _.get(
            this.$store.state.menu.pagesData,
            `[0].primary.data.list[0].seo_title_${this.lang}`
        );
        const website_title = this._tr(
            _.get(this.indexData, "siteSettings.website_title")
        );
        let titleData = this.matchedWineData
            ? _.get(this.matchedWineData, `title`) +
              " " +
              _.get(this.matchedWineData, `teaser_1`)
            : innerSeo ||
              _.get(this.selected_menu, ["seo", this.lang, "title"]) ||
              _.get(this.selected_menu, ["titles", this.lang, "title"]);

        const description = this.matchedWineData
            ? _.get(this.matchedWineData, `teaser_2`)
            : _.get(this.selected_menu, ["seo", this.lang, "description"]) ||
              _.get(this.indexData, "siteSettings.website_meta_description");
        const keyword =
            _.get(this.selected_menu, ["seo", this.lang, "keywords"]) ||
            _.get(this.indexData, "siteSettings.website_meta_keywords");
        if (
            !innerSeo &&
            Object.values(this.$route.params).includes("singleview")
        ) {
            titleData = _.get(
                this.$store.state.menu.pagesData,
                `[0].primary.data.list[0].title`
            );
        }
        return {
            title: titleData,
            titleTemplate: titleData
                ? titleData + " | " + website_title
                : website_title,
            meta: [
                {
                    vmid: "description",
                    name: "description",
                    content: description
                },
                {
                    vmid: "keyword",
                    name: "keyword",
                    content: keyword
                }
            ]
        };
    },
    mounted() {
        if (
            document.querySelector("html") === null ||
            document.querySelector("html") === undefined
        ) {
            return;
        }
        document
            .querySelector("html")
            .setAttribute("lang", this.activeLanguage?.id);
    }
};
</script>

<style lang="scss">
body,
html {
    padding: 0;
    margin: 0;
    overflow: hidden;
}

body {
    min-height: 100vh;
    min-height: -webkit-fill-available;
    @media only screen and (max-width: 1023px) {
        overflow-y: auto;
        overflow-x: hidden;
        height: 100vh;
    }
}

html {
    height: -webkit-fill-available;
}

*,
h2,
p {
    font-family: "Noto-Serif", "Noto-Serif-georgian";
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

#app {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;

    &::-webkit-scrollbar {
        display: none;
    }
}

#nav {
    padding: 30px;

    a {
        font-weight: bold;
        color: #2c3e50;

        &.router-link-exact-active {
            color: #42b983;
        }
    }
}
</style>
