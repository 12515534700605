const state = () => ({
    device: {
        type: 'desktop',
        size: 1920,
        innerHeight: 1080
    },
})

const getters = {
    geDevice: (state) => {
        return state.device
    },

}

const actions = {
    setDevice({commit}, value) {
        commit('SET_DEVICE', value)
    },

}

const mutations = {
    SET_DEVICE(state, value) {
        state.device = value
    },

}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
