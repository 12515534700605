import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import mouseCursor from "@/store/modules/mouseCursor";
import ageVerification from "@/store/modules/ageVerification";
import route from "@/store/modules/route";
import scaleComponent from "@/store/modules/scaleComponent";
import scroll from "@/store/modules/scroll";
import header from "@/store/modules/header";
import menu from "@/store/modules/menu";
import resize from "@/store/modules/resize";
import language from "@/store/modules/language";
import video from "@/store/modules/video";
import drag from "@/store/modules/Drag";
import translations from "@/store/modules/translations";

export default new Vuex.Store({
    modules: {
        mouseCursor,
        ageVerification,
        route,
        scaleComponent,
        scroll,
        header,
        menu,
        resize,
        language,
        video,
        drag,
        translations
    },
});
