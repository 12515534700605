import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store/index";
import { DEFAULT_LANGUAGE, SUPPORTED_LANGUAGES } from "@/constants/trans";
import last from "lodash/last";
import gsap from "gsap";
import isEqual from "lodash/isEqual";

const RootTemplate = () => import("@/views/RootTemplate");

Vue.use(VueRouter);

const routes = [
    {
        path: "/:lang",
        component: RootTemplate,
        children: [
            {
                path: ":path1/:path2?/:path3?/:path4?/:path5?"
            }
        ]
    },
    {
        path: "/",
        component: RootTemplate,
        children: [
            {
                path: ":path1/:path2?/:path3?/:path4?/:path5?"
            }
        ]
    }
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes
});

const getParams = route => {
    let lang = store.getters["language/getLang"];
    let withoutLanguage = lang !== route.params?.lang;

    return Object.entries(route.params)
        ?.filter(
            ([param, value]) =>
                (param?.includes("path") && value) || (withoutLanguage && value)
        )
        ?.map(param => param?.[1]);
};

const getPageContentId = (response, to) => {
    let prevItem = {};
    let pageParams = getParams(to);

    return pageParams.map((param, index) => {
        if (index === 0) {
            let contentId = response?.find(
                menu => menu.url_slug === param && menu.pid === 0
            )?.id;
            prevItem = {
                path: param,
                id: contentId
            };

            return contentId;
        }
        let contentId = response?.find(
            menu => menu.url_slug === param && menu.pid === prevItem.id
        )?.id;
        prevItem = {
            path: param,
            id: contentId
        };

        return contentId;
    });
};

const getPageData = (contentid, singleview) => {
    store
        .dispatch("menu/getPageData", {
            singleview,
            contentid
        })
        .catch(e => {
            console.log(e);
        });
};

const returnSupportedLanguage = lang => {
    let langIsCorrect = SUPPORTED_LANGUAGES.filter(item => item.id === lang)
        ?.length;

    return langIsCorrect ? lang : DEFAULT_LANGUAGE;
};

const beforeRouteUpdate = (to, from, next) => {
    let language = returnSupportedLanguage(to?.params?.lang);
    store.dispatch("language/setLang", language);
    let lang = store.getters["language/getLang"];

    let withoutLanguageParams = lang !== to.params?.lang;
    let routeUpdateFromMenu = store.getters["menu/getRouteUpdateFromMenu"];
    let fromPath = withoutLanguageParams
        ? from?.params?.lang
        : from?.params?.path1;
    let toPath = withoutLanguageParams ? to?.params?.lang : to?.params?.path1;
    if (isEqual(to.params, from.params) && from?.matched?.length) {
        next();
        return;
    }
    if (
        (fromPath === "home-page" || !fromPath) &&
        !routeUpdateFromMenu &&
        innerWidth > 1023 &&
        toPath !== "home-page" &&
        toPath
    ) {
        setPageData(to);
        next();
    } else {
        store.dispatch("menu/setRouteUpdateFromMenu", false);
        gsap.to(".rout-changer", {
            opacity: 1,
            pointerEvents: "auto",
            duration: 0.4,
            onComplete: () => {
                setPageData(to);
                next();
            }
        });
    }
};

function objectNextKey(o, id) {
    let keys = Object.keys(o),
        idIndex = keys.indexOf(id),
        nextIndex = (idIndex += 1);
    if (nextIndex >= keys.length) {
        //we're at the end, there is no next
        return;
    }

    return keys[nextIndex];
}

const getSingleView = to => {
    let params = {};
    let singleView = null;

    for (const [key, value] of Object.entries(to.params)) {
        if (value?.includes("singleview")) {
            singleView = objectNextKey(to?.params, key);

            break;
        }

        params[key] = value;
    }
    return {
        singleView: to?.params?.[singleView],
        route: {
            ...to,
            params
        }
    };
};

const setPageData = to => {
    let menuData = store.getters["menu/getMenu"];
    const isWines =
        (to.params.lang === "wines" && to.params.path1?.length) ||
        (to.params.path1 === "wines" && to.params.path2?.length);
    if (!menuData.length) {
        store
            .dispatch("menu/getMenuData")
            .then(response => {
                let routeData = getSingleView(to);
                let contentId = 0;

                if (isWines) {
                    const matchedMenuParent = response?.data?.menus?.find(
                        menu => menu?.url_slug === "wines"
                    );
                    const matchedMenu = response?.data?.menus?.find(
                        menu => menu?.pid === matchedMenuParent?.id
                    );
                    contentId = matchedMenu?.id;
                } else {
                    contentId = getPageContentId(
                        response?.data?.menus,
                        routeData?.route
                    );
                    if (!contentId?.length)
                        contentId = getPageContentId(response?.data?.menus, {
                            params: {
                                lang: store.getters["language/getLang"],
                                path1: "home-page"
                            }
                        });
                }

                getPageData(
                    isWines ? contentId : last(contentId),
                    isWines ? contentId : routeData?.singleView
                );
            })
            .catch(e => {
                console.log(e);
            });
    } else {
        let routeData = getSingleView(to);
        let contentId = 0;
        if (isWines) {
            const matchedMenuParent = menuData?.find(
                menu => menu?.url_slug === "wines"
            );
            const matchedMenu = menuData?.find(
                menu => menu?.pid === matchedMenuParent?.id
            );
            contentId = matchedMenu?.id;
        } else {
            contentId = getPageContentId(menuData, routeData?.route);
        }
        if (!contentId?.length)
            contentId = getPageContentId(menuData, {
                params: {
                    lang: store.getters["language/getLang"],
                    path1: "home-page"
                }
            });

        getPageData(
            isWines ? contentId : last(contentId),
            isWines ? contentId : routeData?.singleView
        );
    }
};

router.beforeEach((to, from, next) => {
    // console.log(to, from, next)
    beforeRouteUpdate(to, from, next);
});

router.afterEach(() => {
    store.dispatch("route/setRouteLoad");
});
export default router;
